@use "scss/base";

.App {
    text-align: center;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
    pointer-events: none;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.dx-datagrid-headers {
    background-color: #eee;
}

.dx-datagrid-headers {
    .dx-datagrid-filter-row .dx-editor-cell .dx-editor-with-menu .dx-texteditor-input,
    .dx-datagrid-filter-row .dx-editor-cell .dx-editor-with-menu .dx-placeholder:before {
        background-color: #eee;
    }
}

body .dx-datagrid-headers .dx-editor-cell .dx-texteditor .dx-texteditor-input {
    background-color: #eee;
}

.dx-datagrid-headers .dx-texteditor-buttons-container {
    background-color: #eee;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.player-wrapper {
    position: relative;
    padding-top: 56.25%;
    /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
    position: absolute;
    top: 0;
    left: 0;
}
