@use "../../../scss/variables" as *;

#dashboard {
    min-height: 100%;
    background-color: $color-third;
    padding: 20px;

    .dashboard-item {
        padding: 10px;

        header {
            font-size: 22px;
            margin-bottom: 12px;
            color: $color-second;
            display: flex;
            align-items: center;
            font-weight: 100;

            svg {
                margin-right: 10px;
            }
        }

        .data {
            background-color: $color-second;
            padding: 10px;
            box-shadow: 3px 3px 3px $color-fourth;
        }

        footer {
            text-align: right;
            padding: 10px 0px;

            a {
                color: $color-second;
                text-decoration: none;

                &:hover {
                    color: $color-main;
                }
            }
        }
    }
}
