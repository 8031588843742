@use "../../../../scss/variables" as *;

#navigation {
    max-width: 200px;
    background-color: darken($color-second, 5);
    box-shadow: 0px 0px 5px #000;
    transition: width 0.2s linear;

    &.open {
        width: 200px;
    }

    &.closed {
        width: 58px;
    }
}
