@use "../../../scss/variables" as *;
@use "../../../scss/base";

#root {
    #header {
        background-color: $color-main;
        padding: 6px 10px;
        box-sizing: border-box;
        position: absolute;
        top: 0px;
        z-index: 2;
        left: 0px;
        right: 0px;
        flex: 0 0 55px;
        color: $color-second;

        a {
            color: $color-second;
        }

        #headerNav {
            position: absolute;
            bottom: 10px;
            left: 20px;
            color: $color-second;
            font-size: 30px;
            width: 200px;

            #back {
                float: left;
                margin-right: 42px;
            }
        }

        #headerContextNav {
            position: absolute;
            top: 15px;
            right: 25px;
            color: $color-second;
            font-size: 20px;
        }

        #logo {
            font-size: 34px;
            font-weight: 900;
            text-decoration: none;
            margin-left: 2px;

            img {
                width: 210px;
            }
        }

        #reloadButton {
            position: absolute;
            top: 0px;
            right: 0px;
            font-size: 18px;
            height: 100%;
            width: 55px;
            box-sizing: border-box;
            background-color: #666;
            line-height: 55px;
            text-align: center;
            cursor: pointer;
            opacity: 1;
            transition: opacity 0.3s linear;
        }

        #navigationButton {
            position: absolute;
            bottom: 10px;
            font-size: 30px;
            right: 20px;
            cursor: pointer;
            display: none;
        }

        #userMenu {
            position: absolute;
            top: 0px;
            right: 55px;
            height: 100%;
            background-color: #555;
            padding: 0px 10px 0px 20px;
            display: flex;
            opacity: 1;
            transition: opacity 0.3s linear;

            .dx-menu-base .dx-menu-item .dx-menu-item-content .dx-menu-item-popout-container .dx-menu-item-popout {
                display: none;
            }
        }

        .dx-menu .dx-menu-item-has-text.dx-menu-item-has-submenu .dx-menu-item-text {
            font-size: 16px;
            color: $color-second;
            font-weight: bold;
        }

        .dx-menu:hover .dx-menu-item-has-text.dx-menu-item-has-submenu .dx-menu-item-text {
            color: grey;
            font-weight: bold;
        }

        .dx-menu .dx-menu-item-has-text.dx-menu-item-expanded .dx-menu-item-text {
            color: grey;
            font-weight: bold;
        }

        .dx-menu-base {
            font-family: "Lato", sans-serif;
        }
    }
}

#root #header.not-logged-in {
    #userMenu {
        opacity: 0;
    }

    #reloadButton {
        opacity: 0;
    }
}

@media screen and (max-width: 767px) {
    #reloadButton {
        display: none;
    }

    #userMenu {
        display: none;
    }
}
