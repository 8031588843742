@use "./variables" as *;

* {
    box-sizing: border-box;
}

body {
    background-color: $color-third;
    font-family: "Lato", sans-serif;
}

#root {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    height: 100vh;
}

.nav {
    background-color: $color-second;
    flex: 0 1;
    nav {
        height: 100%;
        width: 100%;
        overflow-y: auto;
    }
}

#main {
    #content {
        background-color: $color-second;
    }
    &.home {
        #content {
            background-color: $color-second;
        }
    }
}

.buttonDialog {
    p {
        margin-top: 0px;
        // font-weight: bold;
    }
    .buttons {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.draggable {
    cursor: grab;
}
