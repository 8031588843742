@use "../../../scss/base";
.first-group,
.second-group,
.third-group,
.fourth-group {
    padding: 20px;
}

.second-group,
.fourth-group {
    background-color: rgba(191, 191, 191, 0.15);
}

.licenses {
    margin-left: 10px;
    margin-right: 10px;
}
