.updateUserRoleForm{
    margin:10px;
}

.loginDataForm{
    display:flex;
    flex-direction: column;
	align-items:left;
    justify-content: space-around;
}

.passwordHint{
    margin-top:5px;
    margin-left: 20px;
}

.changePasswordButtonDiv {
    padding-top: 10px;
    width: 100%;
}

.changePasswordButton {
    float: right;
    margin-right: 20px;
}